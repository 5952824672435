import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Strong, Em } from "@quarkly/widgets";
import QuarklycommunityKitMarquee from "./QuarklycommunityKitMarquee";
const defaultProps = {
	"justify-content": "space-around",
	"background": "--color-lightD1"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "2rem 2rem 2rem 2rem",
			"children": <Em
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				<Strong>
					ТЕЛЕФОНУЙТЕ ЗА НОМЕРОМ 0992358731
				</Strong>
			</Em>
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "2rem 1rem 2rem 1rem",
			"children": <Em
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				<Strong>
					ТЕЛЕФОНУЙТЕ ЗА НОМЕРОМ 0992358731
				</Strong>
			</Em>
		}
	}
};

const Marquee = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <QuarklycommunityKitMarquee {...rest}>
		<Override slot="Container" width="100%" justify-content="space-around" />
		<Text {...override("text")} />
		<Text {...override("text1")} />
		{children}
	</QuarklycommunityKitMarquee>;
};

Object.assign(Marquee, { ...QuarklycommunityKitMarquee,
	defaultProps,
	overrides
});
export default Marquee;